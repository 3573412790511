
.navbar a{
  text-decoration: none;
  margin: 20px;
}


.authors div{
  margin: 10px 0;
}

.authors span , .authors a{
  margin-right: 20px;
}

.authors a{
  background-color: darkgoldenrod;
  color: white;
  padding: 5px;
  text-decoration: none;
  border-radius: 5px;
}

.authors a:hover{
  background-color: chocolate;
}

.login-form {
  max-width: 600px;
  padding: 40px;
  margin-top: 50px;
}

.login-input {
  margin-bottom: 20px;
}

.login-form .alert{
  margin-top: 20px;
}

.user-nav a {
  text-decoration: none;
}

.data-table-toolbar {
  margin-bottom: 20px;
}

.drawer-content {
  margin: 0px 20px;
}

.files-dialog table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
  
}

.files-dialog td, th {
  border: 0px;
  text-align: left;
  padding: 1px;
  padding-top: 5px;
}

.files-dialog th {
  background-color: rgb(247, 247, 247);
  padding-bottom: 10px;
  padding-top: 5px;
}
